/* common styles start */
@import "colors";
@import "mixins";
@import "mediaqueries";

.page-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
}
.full-width {
    max-width: 1920px;
    margin: 0 auto;
}
.restrict-width {
    max-width: 1366px;
    margin: 0 auto;
}
.medium-width {
    max-width: 1100px;
    margin: 0 auto;
}
.main-wrapper {
    position: relative;
    background: white;
    // margin-top: -137px;
    // @include layout(1439) {
    //     margin-top: -120px;
    // }
    // @include layout(1023) {
    //     margin-top: -92px;
    // }
    // @include layout(767) {
    //     margin-top: -74px;
    // }
}
.ios-wrapper {
    margin-top: -137px;
    @include layout(1439) {
        margin-top: -120px;
    }
    @include layout(1023) {
        margin-top: -92px;
    }
    @include layout(767) {
        margin-top: -74px;
    }
}
.padding-lr {
    padding: 0 40px;

    @include layout(1199) {
        padding: 0 30px;
    }
    @include layout(1023) {
        padding: 0 20px;
    }
}

.mr-left-0 {
    margin-left: 0 !important;
}

.mr-right-0 {
    margin-right: 0 !important;
}

.pad-left-0 {
    padding-left: 0 !important;
}

.pad-right-0 {
    padding-right: 0 !important;
}

.verticalM {
    display: inline-block;
    vertical-align: middle;
}

.verticalT {
    display: inline-block;
    vertical-align: top;
}

.my-show {
    display: flex;
}
.m-d-hide {
    display: block !important;
    opacity: 1;
    @include layout(767) {
        display: none !important;
        opacity: 0;
    }
}

.m-d-show {
    display: none !important;
    opacity: 0;
    @include layout(767) {
        display: block !important;
        opacity: 1;
    }
}
.m-hide {
    display: flex !important;
    opacity: 1;
    @include layout(767) {
        display: none !important;
        opacity: 0;
    }
}

.m-show {
    display: none !important;
    opacity: 0;
    @include layout(767) {
        display: flex !important;
        opacity: 1;
    }
}

.mob-show {
    display: none;
    opacity: 0;
    @include layout(767) {
        display: flex !important;
        opacity: 1;
    }
}

.verticalB {
    display: inline-block;
    vertical-align: bottom;
}
.d-show {
    display: flex !important;
}
.d-hide {
    display: none !important;
}
.hide {
    display: none !important;
}

.show {
    display: inline-block !important;
}

.v-hidden {
    visibility: hidden !important;
}

.v-show {
    visibility: visibility !important;
}

button {
    outline: none;
    border: none;
    font-family: inherit;
    line-height: 1;
    cursor: pointer;
    appearance: none;
}

.buttons-block {
    display: flex;
}

.btn-fill {
    color: #fff;
    font-size: $fs;
    background-color: #71afe0;
    padding: 12px 35px;
    border-radius: 4px;
}

.btn-text {
    color: #71afe0;
    font-size: 14px;
    background-color: transparent;
    margin-left: 20px;
}

/* custom checkbox styles start */
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
}

.checkbox-block {
    margin-bottom: 20px;
    width: 250px;

    span {
        display: inherit;
        padding-left: 26px;
        color: #929ba5;
        font-size: $fs - 2;
    }
}

.checkbox-label {
    color: #504b4a;
    font-size: 16px;
}

input[type="checkbox"] {
    width: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.6em;
    cursor: pointer;
    line-height: 14px;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: url("../images/checkbox-1.svg");
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: url("../images/checkbox-2.svg");
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    transition: all 0.2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* custom checkbox styles end */

/* common styles end */
.input {
    &__block {
        display: block;
        padding: 50px;
    }

    &__text-label {
        color: #000;
        font-size: 16px;
        padding-bottom: 5px;
    }

    &__filed {
        input {
            border-bottom: 1px solid #000;
        }
    }

    &__error-text {
        font-size: 12px;
        color: red;
        line-height: 18px;
    }
}
.primary__title {
    // font-family: $sfb;
    font-size: 60px;
    line-height: 1.2;
    font-weight: 600;
    color: $blue;
    text-align: start;
    @include layout(1760) {
        font-size: 50px;
    }
    @include layout(1600) {
        font-size: 45px;
    }
    @include layout(1365) {
        font-size: 40px;
    }
    @include layout(1279) {
        font-size: 35px;
    }
    @include layout(1199) {
        font-size: 30px;
    }
    @include layout(1023) {
        font-size: 24px;
    }
    @include layout(767) {
        font-size: 22px;
    }
    p {
        display: inline-block;
        padding-right: 8px;
        @include layout(767) {
            padding-right: 4px;
        }
    }
    span {
        color: $orange;
        display: inline-block;
    }
    &.white {
        color: $white;
    }
    &.orange {
        color: $orange;
    }
}
.website__content {
    color: $blue;
    font-size: $fs + 8;
    line-height: 1.6;
    @include layout(1700){
        // font-size: $fs + 4;
    }
    @include layout(1199){
        font-size: $fs;
    }
    @include layout(1023) {
        font-size: $fs - 2;
    }
    @include layout(767) {
        font-size: $fs;
        line-height: 1.4;
    }
    &.white {
        color: $white;
        font-weight: 400;
        font-size: 30px;
        @include layout(1919){
            font-size: $fs + 8;
        }
        @include layout(1199){
            font-size: $fs + 4;
        }
        @include layout(1023) {
            font-size: $fs + 2;
        }
        @include layout(767) {
            font-size: $fs;
        }
    }
}
.contnet__devider {
    width: 110px;
    height: 3px;
    background-color: $blue;
    margin-top: 15px;
    &.white {
        background-color: $white;
    }
    @include layout(1023) {
        margin-top: 15px;
        height: 2px;
        width: 80px;
    }
    @include layout(767) {
        margin-top: 8px;
        height: 1px;
        width: 60px;
    }
    &.center {
        margin-left: auto;
        margin-right: auto;
    }
}
.get-in__touch-button-wrapper {
    display: flex;
    .get-in-touch-button {
        min-width: 212px;
        display: inline-flex;
        align-items: center;
        background: #fff;
        border-radius: 32.5px;
        padding: 8.5px 20px;
        font-size: $fs + 4;
        line-height: 1;
        justify-content: space-between;
        @include layout(1023) {
            font-size: $fs + 2;
        }
        @include layout(767) {
            padding: 4.5px 20px;
            min-width: 155px;
            font-size: $fs;
        }
        p {
            color: $blue;
        }
        img {
            width: 44px;
            margin-left: 10px;
            @include layout(1023) {
                width: 32px;
            }
        }
    }
}

// slick buttons

.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    list-style-type: none;

    li {
        margin: 0px 4px 0px 4px;
        @include layout(767) {
            margin: 0px 2px 0px 2px;
        }
    }

    button {
        display: block;
        background: #7277ed;
        border-radius: 8px;
        width: 23px;
        height: 16px;
        padding: 0;
        border: none;
        text-indent: -9999px;
        @include layout(1100) {
            width: 18px;
            height: 10px;
        }
        @include layout(767) {
            width: 8px;
            height: 8px;
        }
    }

    li.slick-active button {
        background: #ff673d;
        width: 76px;
        @include layout(1100) {
            width: 50px;
        }
        @include layout(767) {
            width: 23px;
        }
    }
}
div.fixed {
    position: absolute;
    display: block;
    padding:5px;
    padding-left: 20px;
    color:rgb(243, 75, 75);
    top: -35px;
    width: 100%;
    @include layout(767){
        top: 35px;
        padding-left: 10px;
    }
    .span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    &.success{
        color: #6fc20d;
    }
}