.website__footer {
    background-image: url("../images/landing/footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 100px;
    @include layout(1439) {
        margin-top: 60px;
    }
    @include layout(1199) {
        margin-top: 90px;
    }
    @include layout(1023) {
        margin-top: 80px;
    }
    @include layout(767) {
        margin-top: 0;
        background-image: url("../images/footer/footer-mobile-bg.svg");
    }
}
.footer__ {
    &section {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 180px;
        padding-bottom: 20px;
        // justify-content: space-between;
        @include layout(1199) {
            padding-top: 120px;
        }
        @include layout(1023) {
            flex-direction: column;
            padding-top: 100px;
            max-width: 700px;
            margin: 0;
        }
        @include layout(767) {
            // max-width: 280px;
            padding-top: 50px;
            position: relative;
            padding-bottom: 0;
        }
    }
    &quick-links-contact {
        width: 100%;
        @include layout(1199) {
        }
        @include layout(1023) {
        }
        @include layout(767) {
        }
    }
    &download-follow {
        width: 37%;
        @include layout(1023) {
            width: 50%;
        }
        @include layout(767) {
            margin-top: 0px;
            width: 100%;
        }
    }
    &contact-cards {
        display: flex;
        // justify-content: space-between;
        margin-bottom: 50px;
        @include layout(1199) {
            margin-bottom: 20px;
        }
        @include layout(1023) {
        }
        @include layout(767) {
            flex-direction: column;
        }
    }
    &contact-card {
        display: inline-flex;
        align-items: flex-start;
        max-width: 235px;
        margin-right: 25px;
        img {
            opacity: 1 !important;
            width: 20px;
            margin-right: 10px;
            @include layout(767) {
                width: 15px;
                margin-right: 6px;
            }
        }
        @include layout(1199) {
            max-width: 200px;
            width: 100%;
            &:nth-child(2) {
                max-width: 150px;
            }
        }
        @include layout(767) {
            margin-bottom: 20px;
        }
        .phone-icon {
            @include layout(767) {
                margin-top: 3px;
            }
        }
        .mail {
            @include layout(767) {
                // margin-top: 4px;
            }
        }
        &.phone-block {
            flex-wrap: wrap;
            .footer__contact-card {
                align-items: center;
                margin-top: -20px;
                @include layout(1023) {
                    margin-top: -5px;
                }
                @include layout(767) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
    }
    &quick-links {
        display: flex;
        width: 100%;
        align-items: center;
        // .footer__quick-link-block {
        //     width: 100%;
        // }
        .footer__follow-block {
            @include layout(767) {
                margin-top: 220px;
            }
        }
        @include layout(767) {
            max-width: 280px;
            flex-wrap: wrap;
            margin-top: -80px;
        }
    }
    &contact-text {
        font-size: $fs + 4;
        line-height: 1.6;
        color: $white;
        margin-top: -2px;
        @include layout(1199) {
            font-size: $fs;
            // line-height: 1.3;
        }
        @include layout(767) {
            line-height: 1.4;
        }
        a {
            display: block;
            color: $white;
        }
    }
    &title {
        font-size: $fs + 8;
        line-height: 1.3;
        color: $white;
        font-weight: 600;
        padding-bottom: 10px;
        @include layout(1023) {
            font-size: $fs + 4;
        }
    }
    &quick-link-block {
        display: flex;
        width: 100%;
        align-items: center;
        margin-left: 30px;
        @include layout(767) {
            margin-left: 0;
            // flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
        }
        a {
            font-size: 20px;
            color: $white;
            margin-right: 25px;
            @include layout(1199) {
                font-size: 16px;
            }
            @include layout(767) {
                margin-bottom: 5px;
            }
        }
    }
    &download-cards {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        @include layout(1023) {
            margin-bottom: 40px;
        }
        @include layout(767) {
            // flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 0;
        }
        a {
            max-width: 217px;
            @include layout(767) {
                padding-right: 10px;
                margin-bottom: 25px;
            }
        }
        a:last-child {
            margin-left: 40px;
            @include layout(767) {
                margin-left: 0;
                padding-right: 0;
            }
        }
        img {
            @include layout(767) {
                max-width: 138px;
            }
        }
    }
    &follow-cards {
        display: flex;
        a {
            width: 43px;
            display: block;
            margin-right: 20px;
            @include layout(1023) {
                width: 34px;
            }
            @include layout(767) {
                width: 30px;
            }
        }
        img {
            filter: grayscale(1);
        }
    }
    &copy-rights {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        // margin-top: 34px;
        width: 100%;
        color: $white;
        line-height: 1.2;
        font-size: $fs + 2;
        padding: 20px 0px;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
        @include layout(1199) {
            font-size: $fs;
            padding: 15px 0;
        }
        @include layout(1023) {
            font-size: $fs - 2;
        }
        @include layout(767) {
            line-height: 1.4;
            margin-top: 20px;
            flex-wrap: wrap;
        }
        p {
            margin-left: 30px;
            @include layout(767) {
                margin-left: 0;
            }
        }
    }
    &get-intouch {
        margin-bottom: 40px;
    }
    &download-block {
        @include layout(767) {
            margin-bottom: -158px;
            margin-top: 100px;
        }
    }
}
.get__intouch-block {
    position: absolute;
    left: 0;
    width: 100%;
    // top: 9%;
    top: -70px;
    @include layout(1023) {
        top: -60px;
    }
    @include layout(767) {
        position: absolute;
        top: inherit;
        bottom: 96px;
    }
}
.get__intouch-wrapper {
    display: flex;
    max-width: 705px;
    width: 100%;
    border-radius: 38px;
    background-color: $white;
    position: relative;
    box-shadow: 0px 0px 7px rgba($color: #000000, $alpha: 0.2);
    height: 70px;
    margin: 0 auto;
    @include layout(1023) {
        height: 60px;
    }
    @include layout(767) {
        height: 40px;
        align-items: center;
        padding-right: 5px;
    }
    input[type="email"],
    input {
        border: none;
        border-top-left-radius: 38px;
        border-bottom-left-radius: 38px;
        padding: 2px 40px;
        color: $blue;
        font-size: $fs + 4;
        height: inherit;
        line-height: 1.2;
        width: calc(100% - 242px);
        &::placeholder {
            color: rgba($color: #000000, $alpha: 0.2);
            font-weight: 400;
        }
        @include layout(767) {
            width: calc(100% - 40px);
            font-size: $fs;
            padding: 2px 10px;
        }
    }
    .get-in-touch-button {
        min-width: 242px;
        img {
            width: 30px;
            @include layout(767) {
                width: 17px;
                margin: 0px 8px;
            }
        }
        border-radius: 38px;
        background-image: linear-gradient(163deg, #fe8d3e 3%, #ffa13a 98%);
        padding-left: 43px;
        padding-right: 43px;
        @include layout(767) {
            border-radius: 50%;
            min-width: auto;
            width: 32px;
            height: 32px;
            padding: 0;
        }
        p {
            color: $white;
            @include layout(767) {
                display: none;
            }
        }
    }
}

.design-brand {
    font-size: 18px;
    display: flex;
    align-items: center;
    @include layout(1199) {
        font-size: 16px;
    }
    @include layout(1023) {
        font-size: 14px;
    }
    @include layout(767) {
        text-align: center;
        margin-top: 10px;
    }
    a {
        padding-left: 5px;
        display: inline-block;
    }
    .divami-logo {
        width: 60px;
        height: 18px;
        // margin-bottom: -1px;
    }
}

//scroll-to-top styles
.scrollToTop {
    width: 50px;
    height: 50px;
    background: white;
    font-weight: bold;
    z-index: 1;
    color: $blue;
    text-decoration: none;
    position: fixed;
    bottom: 70px;
    right: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
    z-index: 999;
    &:hover,
    &:focus {
        color: $blue;
        text-decoration: none;
    }
    @include layout(1199) {
        right: 30px;
        bottom: 55px;
    }
    @include layout(1023) {
        right: 20px;
    }
    @include layout(767) {
        right: 20px;
        bottom: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
    img {
        width: 25px;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        @include layout(1023) {
        }
        @include layout(767) {
            width: 20px;
        }
    }
}