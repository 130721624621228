.changing-future__ {
    &titles {
        max-width: 50%;
        width: 100%;
        @include layout(767) {
            max-width: 350px;
        }
    }
    &section {
        background-image: url("../images/landing/grey.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 180px !important;
        margin-bottom: 40px;
        padding-top: 120px;
        @include layout(1280) {
            // background-size: cover;
        }
        @include layout(1199) {
            padding-bottom: 100px !important;
            margin-bottom: 30px;
            background-size: 100% 100%;
            padding-top: 50px;
        }
        @include layout(1023) {
            padding-top: 40px;
        }
        @include layout(767) {
            padding-bottom: 50px !important;
            background-image: url("../images/landing/grey-mobile.png");
            background-size: 125% 100%;
            margin-bottom: 20px;
            padding-top: 0;
            margin-top: 50px;
        }
    }
    &card-blocks {
        margin-top: -130px;
        @include layout(1679) {
            margin-top: -80px;
        }
        @include layout(1439) {
            margin-top: -70px;
        }
        @include layout(1199) {
            margin-top: -50px;
        }
        @include layout(1023) {
            margin-top: -20px;
        }
        @include layout(767) {
            margin-top: 40px;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
        .changing-future__cards-group:nth-child(1) {
            .changing-future__card:nth-child(1) {
                margin-bottom: 4.5%;
                @include layout(1679) {
                    margin-bottom: 5.5%;
                }
                @include layout(1439) {
                    margin-bottom: 5.5%;
                }
                @include layout(1199) {
                    margin-bottom: 2.5%;
                }
                @include layout(1023) {
                    margin-bottom: 4.5%;
                }
                @include layout(767) {
                    margin-bottom: 0%;
                }
            }
        }
    }
    &cards-group {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        max-width: 1440px;
        align-items: flex-end;
        @include layout(1440) {
            max-width: 1050px;
        }
        &:nth-child(2n) {
            padding-left: 100px;
            @include layout(1100) {
                padding-left: 50px;
            }
            @include layout(767) {
                padding-left: 0px;
            }
        }
        @include layout(767) {
            flex-direction: column;
        }
    }
    &card {
        width: 100%;
        max-width: 550px;
        @include layout(1440) {
            max-width: 440px;
        }
        @include layout(1023) {
            max-width: 385px;
        }
        @include layout(850) {
            max-width: 45%;
        }
        @include layout(767) {
            padding-bottom: 20px;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }
        &:nth-child(2) {
            padding-bottom: 126px;
            @include layout(1100) {
                padding-bottom: 70px;
            }
            @include layout(767) {
                padding-bottom: 20px;
            }
        }
    }
    &icon {
        width: 100px;
        margin-left: -10px;
        @include layout(1100) {
            width: 80px;
            margin-left: -8px;
        }
        @include layout(1023) {
            width: 64px;
            margin-left: -6px;
        }
    }
    &card-title {
        font-size: $fs + 12;
        font-weight: 600;
        color: $blue;
        line-height: 1.3;
        padding-bottom: 10px;
        @include layout(1100) {
            font-size: $fs + 4;
        }
    }
}
