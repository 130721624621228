$fs: 16px;

$source-sans-pro: "Lato"; // light-300, normal-400, bold-700
$noto: "NotoSerif"; // normal-400, bold-700
$she: "Sheline"; // normal-400
$equal: "EqualSans"; // normal-400
$fjalla:"FjallaOne";
$cali:'Calibre';

$backgroundColor: #f8f8f9;

// colors
$red1: #ff1400;
$red2: #ee3724;
$gray1: #474158;
$gray2: #535356;
$black1: #1b1b1b;
$white: #fff;
$green: #0bb23e;
$backgroundColor: #f8f8f9;
$fs: 16px;

$source-sans-pro: 'Source Sans Pro', sans-serif;
