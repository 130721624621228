.making-job__ {
    &ready {
        margin-top: 140px;
        position: relative;
        margin-bottom: 170px;
        @include layout(1200) {
            padding-top: 40px;
            margin-top: 100px;
            margin-bottom: 120px;
        }
        @include layout(1023) {
            padding-top: 80px;
            margin-top: 50px;
            margin-bottom: 100px;
        }
        @include layout(767) {
            padding-top: 0px;
            margin-top: 0px;
            margin-bottom: 100px;
        }
    }
    &wrapper {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        width: 100%;
        @include layout(767) {
            position: relative;
            z-index: 99;
            flex-direction: column-reverse;
        }
    }
    &bg {
        width: 86%;
        margin-left: auto;
        @include layout(1919) {
            width: 84%;
        }
        @include layout(1679) {
            width: 82%;
        }
        @include layout(1439) {
            width: 80%;
        }
        @include layout(1365) {
            width: 81%;
        }
        @include layout(1279) {
            width: 83%;
        }
        @include layout(1199) {
            width: 80%;
        }
        @include layout(767) {
            width: auto;
            margin-top: -190px;
            margin-right: -10px;
            img {
                height: 490px;
                width: auto;
                margin-left: auto;
            }
        }
        @include layout(400) {
            width: 100%;
            left: 6%;
            top: -16px;
            position: relative;
            margin-right: 0;
            img {
                height: auto;
                width: 100%;
            }
        }
    }
    &monkey {
        max-width: 420px;
        position: absolute;
        bottom: -9.5%;
        left: 0;
        // bottom: -13.5%;
        // left: -6%;
        @include layout(1919) {
            bottom: -12%;
        }
        @include layout(1679) {
            max-width: 400px;
            bottom: -15%;
        }
        @include layout(1439) {
            bottom: -17%;
            left: 0;
        }
        @include layout(1365) {
            max-width: 360px;
            bottom: -15.5%;
        }
        @include layout(1279) {
            max-width: 310px;
            bottom: -12.5%;
        }
        @include layout(1199) {
            bottom: -16.5%;
        }
        @include layout(1023) {
            max-width: 235px;
            bottom: -16%;
        }
        @include layout(767) {
            max-width: 130px;
            right: 280px;
            bottom: -8.6%;
            left: auto;
        }
        @include layout(400) {
            right: auto;
            bottom: -7.5%;
        }
    }
    &did-you-know {
        position: absolute;
        // bottom: 20%;
        top: 55%;
        max-width: 650px;
        width: 100%;
        right: 35%;
        @include layout(1919) {
            right: 28%;
        }
        @include layout(1679) {
            // bottom: 18%;
            max-width: 540px;
        }
        @include layout(1439) {
            // bottom: 16%;
            right: 24%;
        }
        @include layout(1365) {
            // bottom: 16%;
            right: 22%;
        }
        @include layout(1279) {
            // bottom: 15%;
            right: 21%;
        }
        @include layout(1199) {
            top: 63%;
            // bottom: 12%;
            right: 11%;
        }
        @include layout(1023) {
            max-width: 400px;
            top: 65%;
            // bottom: 9%;
            right: 11%;
        }
        @include layout(767) {
            max-width: 200px;
            // bottom: 15%;
            z-index: 2;
            right: 8%;
            left: auto;
        }
    }
    &content {
        width: 100%;
        // max-width: 630px;
        max-width: 60%;
        @include layout(1700) {
            max-width: 55%;
            // max-width: 490px;
        }
        @include layout(1679) {
            max-width: 50%;
            // max-width: 490px;
        }
        @include layout(767) {
            max-width: 100%;
            min-height: 229px;
        }
    }
    &main-text {
        margin-top: 15px;
        @include layout(1023) {
            // margin-top: 20px;
        }
        @include layout(767) {
            margin-top: 10px;
        }
    }
    &slider {
        display: flex;
        align-items: center;
        margin-right: 55px;
        margin-top: 7px;
        @include layout(1100) {
            margin-right: 0px;
            margin-top: 0px;
        }
        @include layout(767) {
            justify-content: flex-end;
            position: relative;
            z-index: 1;
            min-height: 264px;
        }
    }
    &slider-group {
        margin-left: 45px;
        @include layout(1023) {
            margin-left: 30px;
        }
    }
    &each-slider-block {
        cursor: pointer;
        &.active {
            .making-job__slider-img {
                transition: all 0.8s ease-in-out;
                width: 300px;
                height: 302px;
                @include layout(1500) {
                    width: 232px;
                    height: 235px;
                }
                @include layout(1200) {
                    width: 180px;
                    height: 182px;
                }
                @include layout(767) {
                    width: 98px;
                    height: 101px;
                }
                img{
                    transform: scale(1.3);
                    @include layout(1200){
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    &bg-img {
        margin-top: 180px;
        @include layout(425) {
        }
    }
    &slider-img {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 252px;
        background-color: $white;
        border-radius: 9px;
        margin-bottom: 45px;
        transition: all 0.8s ease-in-out;
        @include layout(1500) {
            width: 170px;
            height: 172px;
        }
        @include layout(1200) {
            width: 140px;
            height: 142px;
        }
        @include layout(767) {
            width: 72px;
            height: 73px;
            margin-bottom: 30px;
            margin-top: 30px;
        }
        img {
            display: flex;
            transition: all 0.8s ease-in-out;
        }
        &.graduation {
            box-shadow: 0 0 9px 3px rgba(255, 103, 61, 0.4);
            border-radius: 9px 9px 9px 0;
            img {
                width: 128px;
                @include layout(1500) {
                    width: 108px;
                }
                @include layout(1200) {
                    width: 98px;
                }
                @include layout(767) {
                    width: 46px;
                }
            }
        }
        &.progress {
            box-shadow: 0 0 9px 3px rgba(117, 165, 64, 0.38);
            border-radius: 9px 0 9px 9px;
            img {
                width: 132px;
                @include layout(1500) {
                    width: 112px;
                }
                @include layout(1200) {
                    width: 92px;
                }
                @include layout(767) {
                    width: 48px;
                }
            }
        }
        &.target {
            box-shadow: 0 0 9px 3px rgba(236, 99, 118, 0.49);
            border-radius: 0 9px 9px 9px;
            img {
                width: 118px;
                @include layout(1500) {
                    width: 98px;
                }
                @include layout(1200) {
                    width: 72px;
                }
                @include layout(767) {
                    width: 42px;
                }
            }
        }
        &.mobile {
            box-shadow: 0 0 9px 3px rgba(78, 84, 200, 0.36);
            border-radius: 9px 9px 0 9px;
            img {
                width: 93px;
                @include layout(1500) {
                    width: 73px;
                }
                @include layout(1200) {
                    width: 53px;
                }
                @include layout(767) {
                    width: 31px;
                }
            }
        }
    }
}
