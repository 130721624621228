$red1: #ff1400;
$red2: #ee3724;
$gray1: #474158;
$gray2: #535356;
$gray3: #191648;
$grey4:#232323;
$grey5:#181D23;
$black1: #1b1b1b;
$white: #fff;
$green: #0bb23e;
$blue: #191648;
$orange: #FF673D