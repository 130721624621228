@font-face {
	font-family: 'SF-Pro-Bold';
	src: url("../fonts/SF-Pro-Display-Bold.otf");
	font-weight: 700;
	font-style: normal;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

body {
	color: #191648;
	background: #494747;
	font-family: "Source Sans Pro", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	margin: 0;
	padding: 0;
	line-height: 24px;
	overflow-y: scroll;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: none;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-overflow-scrolling: auto;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body :focus {
	outline: none;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.button {
	width: 100%;
	height: 45px;
	line-height: 45px;
	padding: 0 10px;
	background: #192431;
	font-family: "Source Sans Pro", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	border: none;
	color: #fff;
	cursor: pointer;
}

.button:focus {
	outline: none;
}

/*reset inputs*/

input,
select,
option,
textarea {
	font-family: "Source Sans Pro", sans-serif;
	font-style: normal;
	font-size: 16px;
	font-weight: 300;
}

input,
select,
textarea,
button {
	-webkit-tap-highlight-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

input[type="text"],
input[type="password"] {
	width: 100%;
	background: transparent;
	border: 1px solid #dadbdb;
}

input[type="text"]:focus,
input[type="password"]:focus {
	outline: none;
}

textarea {
	resize: none;
	width: 100%;
	background: transparent;
}

textarea:focus {
	outline: none;
}

.form__error {
	display: none;
	color: #ff0000;
	font-size: 14px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

svg {
	max-width: 100%;
	max-height: 100%;
	vertical-align: top;
}

img {
	display: block;
	width: 100%;
}

a {
	text-decoration: none;
	color: #191648;
	-webkit-tap-highlight-color: transparent;
}

a:focus {
	outline: none;
}

a img {
	border: none;
	outline: none;
}

input[type=text]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type=text]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

select {
	appearance: none;
}

select::-ms-expand {
	display: none;
}

/* common styles start */

.page-wrapper {
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
}

.full-width {
	max-width: 1920px;
	margin: 0 auto;
}

.restrict-width {
	max-width: 1366px;
	margin: 0 auto;
}

.medium-width {
	max-width: 1100px;
	margin: 0 auto;
}

.main-wrapper {
	position: relative;
	background: white;
}

.ios-wrapper {
	margin-top: -137px;
}

.padding-lr {
	padding: 0 40px;
}

.mr-left-0 {
	margin-left: 0 !important;
}

.mr-right-0 {
	margin-right: 0 !important;
}

.pad-left-0 {
	padding-left: 0 !important;
}

.pad-right-0 {
	padding-right: 0 !important;
}

.verticalM {
	display: inline-block;
	vertical-align: middle;
}

.verticalT {
	display: inline-block;
	vertical-align: top;
}

.my-show {
	display: flex;
}

.m-d-hide {
	display: block !important;
	opacity: 1;
}

.m-d-show {
	display: none !important;
	opacity: 0;
}

.m-hide {
	display: flex !important;
	opacity: 1;
}

.m-show {
	display: none !important;
	opacity: 0;
}

.mob-show {
	display: none;
	opacity: 0;
}

.verticalB {
	display: inline-block;
	vertical-align: bottom;
}

.d-show {
	display: flex !important;
}

.d-hide {
	display: none !important;
}

.hide {
	display: none !important;
}

.show {
	display: inline-block !important;
}

.v-hidden {
	visibility: hidden !important;
}

.v-show {
	visibility: visibility !important;
}

button {
	outline: none;
	border: none;
	font-family: inherit;
	line-height: 1;
	cursor: pointer;
	appearance: none;
}

.buttons-block {
	display: flex;
}

.btn-fill {
	color: #fff;
	font-size: 16px;
	background-color: #71afe0;
	padding: 12px 35px;
	border-radius: 4px;
}

.btn-text {
	color: #71afe0;
	font-size: 14px;
	background-color: transparent;
	margin-left: 20px;
}

/* custom checkbox styles start */

.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	padding-top: 15px;
}

.checkbox-block {
	margin-bottom: 20px;
	width: 250px;
}

.checkbox-block span {
	display: inherit;
	padding-left: 26px;
	color: #929ba5;
	font-size: 14px;
}

.checkbox-label {
	color: #504b4a;
	font-size: 16px;
}

input[type="checkbox"] {
	width: inherit;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position: absolute;
	left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
	position: relative;
	padding-left: 1.6em;
	cursor: pointer;
	line-height: 14px;
}

/* checkbox aspect */

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
	content: url("../images/checkbox-1.svg");
	position: absolute;
	left: 0;
	top: 0;
	width: 14px;
	height: 14px;
}

/* checked mark aspect */

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
	content: url("../images/checkbox-2.svg");
	position: absolute;
	top: 0;
	left: 0;
	width: 14px;
	height: 14px;
	transition: all 0.2s;
}

/* checked mark aspect changes */

[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0);
}

[type="checkbox"]:checked + label:after {
	opacity: 1;
	transform: scale(1);
}

/* custom checkbox styles end */

/* common styles end */

.input__block {
	display: block;
	padding: 50px;
}

.input__text-label {
	color: #000;
	font-size: 16px;
	padding-bottom: 5px;
}

.input__filed input {
	border-bottom: 1px solid #000;
}

.input__error-text {
	font-size: 12px;
	color: red;
	line-height: 18px;
}

.primary__title {
	font-size: 60px;
	line-height: 1.2;
	font-weight: 600;
	color: #191648;
	text-align: start;
}

.primary__title p {
	display: inline-block;
	padding-right: 8px;
}

.primary__title span {
	color: #FF673D;
	display: inline-block;
}

.primary__title.white {
	color: #fff;
}

.primary__title.orange {
	color: #FF673D;
}

.website__content {
	color: #191648;
	font-size: 24px;
	line-height: 1.6;
}

.website__content.white {
	color: #fff;
	font-weight: 400;
	font-size: 30px;
}

.contnet__devider {
	width: 110px;
	height: 3px;
	background-color: #191648;
	margin-top: 15px;
}

.contnet__devider.white {
	background-color: #fff;
}

.contnet__devider.center {
	margin-left: auto;
	margin-right: auto;
}

.get-in__touch-button-wrapper {
	display: flex;
}

.get-in__touch-button-wrapper .get-in-touch-button {
	min-width: 212px;
	display: inline-flex;
	align-items: center;
	background: #fff;
	border-radius: 32.5px;
	padding: 8.5px 20px;
	font-size: 20px;
	line-height: 1;
	justify-content: space-between;
}

.get-in__touch-button-wrapper .get-in-touch-button p {
	color: #191648;
}

.get-in__touch-button-wrapper .get-in-touch-button img {
	width: 44px;
	margin-left: 10px;
}

.slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
	list-style-type: none;
}

.slick-dots li {
	margin: 0px 4px 0px 4px;
}

.slick-dots button {
	display: block;
	background: #7277ed;
	border-radius: 8px;
	width: 23px;
	height: 16px;
	padding: 0;
	border: none;
	text-indent: -9999px;
}

.slick-dots li.slick-active button {
	background: #ff673d;
	width: 76px;
}

div.fixed {
	position: absolute;
	display: block;
	padding: 5px;
	padding-left: 20px;
	color: #f34b4b;
	top: -35px;
	width: 100%;
}

div.fixed .span {
	display: inline-block;
	vertical-align: middle;
	line-height: normal;
}

div.fixed.success {
	color: #6fc20d;
}

.header-common__block {
	transition: all 0.5s ease-in-out;
	display: flex;
	justify-content: space-between;
	padding: 30px 40px;
	align-items: center;
	position: relative;
	z-index: 1;
}

.header__links-block {
	display: flex;
}

.header__logo-link {
	max-width: 220px;
	width: 100%;
	display: block;
	transition: all 0.5s ease-in-out;
}

.header__logo-link img {
	transition: all 0.5s ease-in-out;
}

.header-common__link {
	position: relative;
	transition: all 0.5s ease-in-out;
}

.header-common__link::after {
	content: "";
	position: absolute;
	width: 0%;
	height: 1px;
	right: 0;
	bottom: -13px;
	background-color: #fff;
	transition: all 0.2s ease-in-out;
}

.header-common__link.active::after,
.header-common__link:hover::after {
	width: 100%;
}

.header-common__link a {
	transition: all 0.5s ease-in-out;
	display: block;
	font-size: 30px;
	color: #fff;
	font-weight: 600;
}

.header-common__link + .header-common__link {
	margin-left: 50px;
}

.header-common {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 901;
	transition: all 0.5s ease-in-out;
}

.header-common.header-scroll {
	transition: all 0.5s ease-in-out;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	top: 0;
	position: relative;
}

.header-common.header-fixed {
	position: fixed;
	background: linear-gradient(223deg, rgba(255, 115, 61, 0.5) 0%, rgba(255, 159, 56, 0.5) 59%, rgba(254, 141, 62, 0.5) 100%);
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header-common__block {
	padding-top: 20px;
	padding-bottom: 20px;
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header__logo {
	width: 96px;
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header__logo-block {
	margin-top: -6px;
	align-self: inherit;
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header-common__link {
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header-common__link::after {
	bottom: -8px;
	transition: all 0.5s ease-in-out;
}

.header-common.header-fixed .header-common__link a {
	transition: all 0.5s ease-in-out;
	font-size: 24px;
}

.header-common.scrollUp {
	transform: translate3d(0px, -75px, 0px);
	transition: all 0.5s ease-in-out;
}

.loader-block {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}

.banner__img-wrapper {
	position: relative;
}

.banner__img-wrapper::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(200deg, #ff943c, rgba(255, 148, 60, 0) 30%);
	right: 0;
	top: 0;
	margin: auto;
}

.banner__img-wrapper .mobile-banner {
	display: none;
}

.banner__section {
	min-height: 535px;
	position: relative;
}

.banner__section .get-in__touch-button-wrapper {
	margin-top: 34px;
}

.banner__section-content {
	position: absolute;
	z-index: 3;
	left: 0;
	top: 30%;
	max-width: 1024px;
	width: 100%;
	max-width: 900px;
}

.banner__section-content .website__content.white {
	line-height: 1.4;
	font-weight: 400;
	font-size: 28px;
}

.banner__section-content .website__content.white span {
	display: inline-block;
}

.banner__section-content .contnet__devider.white {
	margin-bottom: 15px;
}

.banner__main-img {
	position: absolute;
	right: -10px;
	top: 0;
	height: 100%;
	z-index: 1;
}

.banner__main-img img {
	height: 100%;
	width: auto;
}

.about-banner__section {
	min-height: 430px;
}

.about-banner__section .contnet__devider {
	margin-top: 5px;
	margin-bottom: 25px;
}

.about-banner__section .banner__section-content {
	max-width: 940px;
}

.about-banner__section .website__content {
	font-size: 30px;
}

.header-banner__section.home-banner {
	margin-top: -5px;
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}

.making-job__ready {
	margin-top: 140px;
	position: relative;
	margin-bottom: 170px;
}

.making-job__wrapper {
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 0;
	width: 100%;
}

.making-job__bg {
	width: 86%;
	margin-left: auto;
}

.making-job__monkey {
	max-width: 420px;
	position: absolute;
	bottom: -9.5%;
	left: 0;
}

.making-job__did-you-know {
	position: absolute;
	top: 55%;
	max-width: 650px;
	width: 100%;
	right: 35%;
}

.making-job__content {
	width: 100%;
	max-width: 60%;
}

.making-job__main-text {
	margin-top: 15px;
}

.making-job__slider {
	display: flex;
	align-items: center;
	margin-right: 55px;
	margin-top: 7px;
}

.making-job__slider-group {
	margin-left: 45px;
}

.making-job__each-slider-block {
	cursor: pointer;
}

.making-job__each-slider-block.active .making-job__slider-img {
	transition: all 0.8s ease-in-out;
	width: 300px;
	height: 302px;
}

.making-job__each-slider-block.active .making-job__slider-img img {
	transform: scale(1.3);
}

.making-job__bg-img {
	margin-top: 180px;
}

.making-job__slider-img {
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	height: 252px;
	background-color: #fff;
	border-radius: 9px;
	margin-bottom: 45px;
	transition: all 0.8s ease-in-out;
}

.making-job__slider-img img {
	display: flex;
	transition: all 0.8s ease-in-out;
}

.making-job__slider-img.graduation {
	box-shadow: 0 0 9px 3px rgba(255, 103, 61, 0.4);
	border-radius: 9px 9px 9px 0;
}

.making-job__slider-img.graduation img {
	width: 128px;
}

.making-job__slider-img.progress {
	box-shadow: 0 0 9px 3px rgba(117, 165, 64, 0.38);
	border-radius: 9px 0 9px 9px;
}

.making-job__slider-img.progress img {
	width: 132px;
}

.making-job__slider-img.target {
	box-shadow: 0 0 9px 3px rgba(236, 99, 118, 0.49);
	border-radius: 0 9px 9px 9px;
}

.making-job__slider-img.target img {
	width: 118px;
}

.making-job__slider-img.mobile {
	box-shadow: 0 0 9px 3px rgba(78, 84, 200, 0.36);
	border-radius: 9px 9px 0 9px;
}

.making-job__slider-img.mobile img {
	width: 93px;
}

.changing-future__titles {
	max-width: 50%;
	width: 100%;
}

.changing-future__section {
	background-image: url("../images/landing/grey.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding-bottom: 180px !important;
	margin-bottom: 40px;
	padding-top: 120px;
}

.changing-future__card-blocks {
	margin-top: -130px;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 4.5%;
}

.changing-future__cards-group {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
	max-width: 1440px;
	align-items: flex-end;
}

.changing-future__cards-group:nth-child(2n) {
	padding-left: 100px;
}

.changing-future__card {
	width: 100%;
	max-width: 550px;
}

.changing-future__card:nth-child(2) {
	padding-bottom: 126px;
}

.changing-future__icon {
	width: 100px;
	margin-left: -10px;
}

.changing-future__card-title {
	font-size: 28px;
	font-weight: 600;
	color: #191648;
	line-height: 1.3;
	padding-bottom: 10px;
}

.count__blocks {
	max-width: 1360px;
	width: 100%;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.count__card {
	display: inline-flex;
	flex-direction: column;
	border-right: 1px solid;
	padding: 0px 6%;
	margin-bottom: 50px;
}

.count__card:last-child {
	border-right: none;
}

.count__number {
	text-align: center;
}

.count__text {
	font-size: 24px;
	font-weight: 600;
	color: #191648;
}

.partners__section {
	margin-top: 80px;
}

.partners__wrapper {
	max-width: 1440px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.partners__group {
	display: flex !important;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;
}

.partners__logo {
	width: 16%;
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}

.partners__logo-wrapper {
	margin-left: auto;
	margin-right: auto;
	padding: 5px;
	max-width: 200px;
}

.testimonial__titles {
	display: flex;
	align-items: center;
	justify-content: center;
}

.testimonial__titles .primary__title {
	text-align: center;
}

.testimonial__section {
	margin-top: 150px;
}

.testimonial__slider-block {
	max-width: 1200px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 125px;
}

.testimonial__slider {
	display: flex !important;
	margin-top: 80px;
	margin-bottom: 50px;
	align-items: center;
	justify-content: space-around;
	min-height: 478px;
}

.testimonial__slider-conetnt {
	max-width: 650px;
	width: 100%;
}

.testimonial__slider-conetnt .quotation {
	width: 94px;
}

.testimonial__slider-conetnt .website__content {
	padding-top: 10px;
}

.testimonial__user-name {
	font-size: 28px;
	color: #191648;
	font-weight: 600;
	line-height: 1.4;
}

.testimonial__user-name.designation {
	color: #4E54C8;
	font-size: 22px;
}

.testimonial__user-name.comapny-name {
	font-size: 22px;
}

.testimonial__slider-profile-pic {
	position: relative;
}

.testimonial__slider-round-pic {
	width: 163px;
	position: absolute;
	left: -64px;
	top: -60px;
}

.user__profile-pic {
	max-width: 300px;
	border-radius: 50px;
	position: relative;
}

.user__profile-pic img {
	position: relative;
	z-index: 1;
	border-radius: 50px;
}

.user__profile-pic::after {
	content: "";
	width: 100%;
	height: 100%;
	opacity: 0.7;
	background: #d8d8d8;
	border-radius: 50px;
	position: absolute;
	left: 11%;
	top: 5%;
	z-index: 0;
	transform: rotate(9deg);
}

.mobile__slides-block {
	display: flex;
	margin-top: -17%;
	position: relative;
	justify-content: center;
	z-index: 1;
}

.mobile__slider-content {
	max-width: 650px;
	width: 100%;
	margin-left: 5%;
	padding-top: 20%;
}

.mobile__slider-content .website__content {
	padding-top: 20px;
}

.mobile__slider-content .primary__title span {
	display: block;
}

.mobile__slider {
	max-width: 641px;
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-left: -8%;
}

.mobile__slider::before {
	content: "";
	width: 25%;
	height: 4%;
	background: black;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	position: absolute;
	top: 2%;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 3;
}

.mobile__slider img {
	position: relative;
	z-index: 1;
}

.mobile__slider-slick {
	position: absolute;
	top: 3.3%;
	left: 23.4%;
	height: 91.5%;
	width: 52.8%;
	overflow: hidden;
	z-index: 2;
	border-radius: 20px;
}

.mobile__bg-image-block {
	width: calc(100% + 40px);
	margin-left: -20px;
	margin-bottom: -68%;
}

.take__control-img {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.take__control-img svg {
	width: 60% !important;
	margin-right: 10%;
}

.take__control-bg-img {
	margin-top: -10%;
}

.take__control-section {
	position: relative;
	margin-bottom: 30px;
}

.take__control-wrapper {
	position: relative;
}

.take__control-img svg {
	width: 100%;
}

.take__control-content {
	position: absolute;
	top: 10%;
	width: 65%;
	padding-left: 40px;
}

.take__control-content .website__content {
	width: 80%;
	padding-top: 20px;
}

.take__control-content .primary__title span {
	display: inherit;
}

.website__footer {
	background-image: url("../images/landing/footer.png");
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	margin-top: 100px;
}

.footer__section {
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding-top: 180px;
	padding-bottom: 20px;
}

.footer__quick-links-contact {
	width: 100%;
}

.footer__download-follow {
	width: 37%;
}

.footer__contact-cards {
	display: flex;
	margin-bottom: 50px;
}

.footer__contact-card {
	display: inline-flex;
	align-items: flex-start;
	max-width: 235px;
	margin-right: 25px;
}

.footer__contact-card img {
	opacity: 1 !important;
	width: 20px;
	margin-right: 10px;
}

.footer__contact-card.phone-block {
	flex-wrap: wrap;
}

.footer__contact-card.phone-block .footer__contact-card {
	align-items: center;
	margin-top: -20px;
}

.footer__quick-links {
	display: flex;
	width: 100%;
	align-items: center;
}

.footer__contact-text {
	font-size: 20px;
	line-height: 1.6;
	color: #fff;
	margin-top: -2px;
}

.footer__contact-text a {
	display: block;
	color: #fff;
}

.footer__title {
	font-size: 24px;
	line-height: 1.3;
	color: #fff;
	font-weight: 600;
	padding-bottom: 10px;
}

.footer__quick-link-block {
	display: flex;
	width: 100%;
	align-items: center;
	margin-left: 30px;
}

.footer__quick-link-block a {
	font-size: 20px;
	color: #fff;
	margin-right: 25px;
}

.footer__download-cards {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
}

.footer__download-cards a {
	max-width: 217px;
}

.footer__download-cards a:last-child {
	margin-left: 40px;
}

.footer__follow-cards {
	display: flex;
}

.footer__follow-cards a {
	width: 43px;
	display: block;
	margin-right: 20px;
}

.footer__follow-cards img {
	filter: grayscale(1);
}

.footer__copy-rights {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	color: #fff;
	line-height: 1.2;
	font-size: 18px;
	padding: 20px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.footer__copy-rights p {
	margin-left: 30px;
}

.footer__get-intouch {
	margin-bottom: 40px;
}

.get__intouch-block {
	position: absolute;
	left: 0;
	width: 100%;
	top: -70px;
}

.get__intouch-wrapper {
	display: flex;
	max-width: 705px;
	width: 100%;
	border-radius: 38px;
	background-color: #fff;
	position: relative;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
	height: 70px;
	margin: 0 auto;
}

.get__intouch-wrapper input[type="email"],
.get__intouch-wrapper input {
	border: none;
	border-top-left-radius: 38px;
	border-bottom-left-radius: 38px;
	padding: 2px 40px;
	color: #191648;
	font-size: 20px;
	height: inherit;
	line-height: 1.2;
	width: calc(100% - 242px);
}

.get__intouch-wrapper input[type="email"]::placeholder,
.get__intouch-wrapper input::placeholder {
	color: rgba(0, 0, 0, 0.2);
	font-weight: 400;
}

.get__intouch-wrapper .get-in-touch-button {
	min-width: 242px;
	border-radius: 38px;
	background-image: linear-gradient(163deg, #fe8d3e 3%, #ffa13a 98%);
	padding-left: 43px;
	padding-right: 43px;
}

.get__intouch-wrapper .get-in-touch-button img {
	width: 30px;
}

.get__intouch-wrapper .get-in-touch-button p {
	color: #fff;
}

.design-brand {
	font-size: 18px;
	display: flex;
	align-items: center;
}

.design-brand a {
	padding-left: 5px;
	display: inline-block;
}

.design-brand .divami-logo {
	width: 60px;
	height: 18px;
}

.scrollToTop {
	width: 50px;
	height: 50px;
	background: white;
	font-weight: bold;
	z-index: 1;
	color: #191648;
	text-decoration: none;
	position: fixed;
	bottom: 70px;
	right: 40px;
	display: none;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 16px;
	text-align: center;
	vertical-align: middle;
	line-height: 50px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
	z-index: 999;
}

.scrollToTop:hover,
.scrollToTop:focus {
	color: #191648;
	text-decoration: none;
}

.scrollToTop img {
	width: 25px;
	transform: rotate(-90deg);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

@media (max-width: 1919px) {

.website__content.white {
	font-size: 24px;
}

.about-banner__section .banner__section-content {
	max-width: 850px;
}

.about-banner__section .website__content {
	font-size: 24px;
}

.making-job__bg {
	width: 84%;
}

.making-job__monkey {
	bottom: -12%;
}

.making-job__did-you-know {
	right: 28%;
}

.take__control-content {
	width: 60%;
}

}

@media (max-width: 1760px) {

.primary__title {
	font-size: 50px;
}

.banner__section-content {
	top: 25%;
	max-width: 770px;
}

}

@media (max-width: 1700px) {

.making-job__content {
	max-width: 55%;
}

}

@media (max-width: 1680px) {

.testimonial__slider {
	min-height: 420px;
}

}

@media (max-width: 1679px) {

.about-banner__section .banner__section-content {
	max-width: 750px;
}

.making-job__bg {
	width: 82%;
}

.making-job__monkey {
	max-width: 400px;
	bottom: -15%;
}

.making-job__did-you-know {
	max-width: 540px;
}

.making-job__content {
	max-width: 50%;
}

.changing-future__card-blocks {
	margin-top: -80px;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 5.5%;
}

.mobile__slider {
	max-width: 500px;
}

}

@media (max-width: 1600px) {

.primary__title {
	font-size: 45px;
}

}

@media (max-width: 1500px) {

.making-job__each-slider-block.active .making-job__slider-img {
	width: 232px;
	height: 235px;
}

.making-job__slider-img {
	width: 170px;
	height: 172px;
}

.making-job__slider-img.graduation img {
	width: 108px;
}

.making-job__slider-img.progress img {
	width: 112px;
}

.making-job__slider-img.target img {
	width: 98px;
}

.making-job__slider-img.mobile img {
	width: 73px;
}

.mobile__slider-content {
	max-width: 500px;
	margin-left: 3%;
}

}

@media (max-width: 1440px) {

.changing-future__cards-group {
	max-width: 1050px;
}

.changing-future__card {
	max-width: 440px;
}

}

@media (max-width: 1439px) {

.ios-wrapper {
	margin-top: -120px;
}

.about-banner__section .banner__section-content {
	max-width: 670px;
}

.making-job__bg {
	width: 80%;
}

.making-job__monkey {
	bottom: -17%;
	left: 0;
}

.making-job__did-you-know {
	right: 24%;
}

.changing-future__card-blocks {
	margin-top: -70px;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 5.5%;
}

.partners__wrapper {
	max-width: 1280px;
}

.partners__logo-wrapper {
	max-width: 150px;
}

.website__footer {
	margin-top: 60px;
}

}

@media (max-width: 1366px) {

.header__logo-link {
	max-width: 160px;
}

.header-common__link a {
	font-size: 24px;
}

}

@media (max-width: 1365px) {

.primary__title {
	font-size: 40px;
}

.about-banner__section .banner__section-content {
	max-width: 630px;
}

.making-job__bg {
	width: 81%;
}

.making-job__monkey {
	max-width: 360px;
	bottom: -15.5%;
}

.making-job__did-you-know {
	right: 22%;
}

.testimonial__slider-conetnt .quotation {
	width: 75px;
}

.testimonial__slider-round-pic {
	width: 125px;
	position: absolute;
	left: -30px;
	top: -30px;
}

.user__profile-pic {
	max-width: 300px;
}

}

@media (max-width: 1279px) {

.primary__title {
	font-size: 35px;
}

.banner__section-content {
	max-width: 630px;
}

.making-job__bg {
	width: 83%;
}

.making-job__monkey {
	max-width: 310px;
	bottom: -12.5%;
}

.making-job__did-you-know {
	right: 21%;
}

.partners__logo-wrapper {
	max-width: 130px;
}

.testimonial__slider {
	margin-top: 70px;
	margin-bottom: 70px;
}

.mobile__slider-content {
	max-width: 450px;
}

}

@media (max-width: 1200px) {

.making-job__ready {
	padding-top: 40px;
	margin-top: 100px;
	margin-bottom: 120px;
}

.making-job__each-slider-block.active .making-job__slider-img {
	width: 180px;
	height: 182px;
}

.making-job__each-slider-block.active .making-job__slider-img img {
	transform: scale(1.1);
}

.making-job__slider-img {
	width: 140px;
	height: 142px;
}

.making-job__slider-img.graduation img {
	width: 98px;
}

.making-job__slider-img.progress img {
	width: 92px;
}

.making-job__slider-img.target img {
	width: 72px;
}

.making-job__slider-img.mobile img {
	width: 53px;
}

}

@media (max-width: 1199px) {

.padding-lr {
	padding: 0 30px;
}

.primary__title {
	font-size: 30px;
}

.website__content {
	font-size: 16px;
}

.website__content.white {
	font-size: 20px;
}

.header-common__link + .header-common__link {
	margin-left: 40px;
}

.banner__section-content {
	top: 30%;
	max-width: 570px;
}

.banner__section-content .website__content.white {
	font-size: 20px;
}

.about-banner__section .banner__section-content {
	max-width: 540px;
}

.about-banner__section .website__content {
	font-size: 20px;
}

.making-job__bg {
	width: 80%;
}

.making-job__monkey {
	bottom: -16.5%;
}

.making-job__did-you-know {
	top: 63%;
	right: 11%;
}

.changing-future__section {
	padding-bottom: 100px !important;
	margin-bottom: 30px;
	background-size: 100% 100%;
	padding-top: 50px;
}

.changing-future__card-blocks {
	margin-top: -50px;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 2.5%;
}

.partners__section {
	margin-top: 60px;
}

.testimonial__section {
	margin-top: 100px;
}

.testimonial__slider-block {
	margin-bottom: 80px;
}

.testimonial__slider {
	min-height: 356px;
	margin-top: 60px;
	margin-bottom: 60px;
}

.mobile__slider-content {
	max-width: 400px;
}

.mobile__slider {
	max-width: 350px;
	margin-left: -5%;
}

.take__control-content {
	width: 50%;
	padding-left: 30px;
}

.website__footer {
	margin-top: 90px;
}

.footer__section {
	padding-top: 120px;
}

.footer__contact-cards {
	margin-bottom: 20px;
}

.footer__contact-card {
	max-width: 200px;
	width: 100%;
}

.footer__contact-card:nth-child(2) {
	max-width: 150px;
}

.footer__contact-text {
	font-size: 16px;
}

.footer__quick-link-block a {
	font-size: 16px;
}

.footer__copy-rights {
	font-size: 16px;
	padding: 15px 0;
}

.design-brand {
	font-size: 16px;
}

.scrollToTop {
	right: 30px;
	bottom: 55px;
}

}

@media (max-width: 1100px) {

.slick-dots button {
	width: 18px;
	height: 10px;
}

.slick-dots li.slick-active button {
	width: 50px;
}

.making-job__slider {
	margin-right: 0px;
	margin-top: 0px;
}

.changing-future__cards-group:nth-child(2n) {
	padding-left: 50px;
}

.changing-future__card:nth-child(2) {
	padding-bottom: 70px;
}

.changing-future__icon {
	width: 80px;
	margin-left: -8px;
}

.changing-future__card-title {
	font-size: 20px;
}

.count__text {
	font-size: 18px;
}

}

@media (max-width: 1099px) {

.testimonial__slider-conetnt {
	max-width: 450px;
}

}

@media (max-width: 1023px) {

.ios-wrapper {
	margin-top: -92px;
}

.padding-lr {
	padding: 0 20px;
}

.primary__title {
	font-size: 24px;
}

.website__content {
	font-size: 14px;
}

.website__content.white {
	font-size: 18px;
}

.contnet__devider {
	margin-top: 15px;
	height: 2px;
	width: 80px;
}

.get-in__touch-button-wrapper .get-in-touch-button {
	font-size: 18px;
}

.get-in__touch-button-wrapper .get-in-touch-button img {
	width: 32px;
}

.header-common__block {
	padding: 20px;
}

.header__logo-link {
	max-width: 140px;
}

.header-common__link::after {
	bottom: -8px;
}

.header-common__link a {
	font-size: 20px;
}

.header-common__link + .header-common__link {
	margin-left: 30px;
}

.header-common.header-fixed .header-common__link a {
	font-size: 16px;
}

.banner__section .get-in__touch-button-wrapper {
	margin-top: 20px;
}

.banner__section-content {
	max-width: 400px;
	top: 20%;
}

.banner__section-content .website__content.white {
	font-size: 18px;
}

.banner__section-content .contnet__devider.white {
	margin-bottom: 10px;
}

.about-banner__section .banner__section-content {
	max-width: 400px;
}

.about-banner__section .website__content {
	font-size: 18px;
}

.making-job__ready {
	padding-top: 80px;
	margin-top: 50px;
	margin-bottom: 100px;
}

.making-job__monkey {
	max-width: 235px;
	bottom: -16%;
}

.making-job__did-you-know {
	max-width: 400px;
	top: 65%;
	right: 11%;
}

.making-job__slider-group {
	margin-left: 30px;
}

.changing-future__section {
	padding-top: 40px;
}

.changing-future__card-blocks {
	margin-top: -20px;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 4.5%;
}

.changing-future__card {
	max-width: 385px;
}

.changing-future__icon {
	width: 64px;
	margin-left: -6px;
}

.count__card {
	padding: 0px 4%;
	margin-bottom: 40px;
}

.partners__section {
	margin-top: 40px;
}

.partners__group {
	margin-top: 40px;
}

.partners__logo {
	margin-bottom: 40px;
}

.testimonial__section {
	margin-top: 80px;
}

.testimonial__slider {
	min-height: 310px;
	justify-content: space-between;
	padding-right: 50px;
	margin-bottom: 60px;
	margin-top: 40px;
}

.testimonial__slider-conetnt .quotation {
	width: 56px;
}

.testimonial__slider-conetnt {
	max-width: 380px;
}

.testimonial__user-name {
	font-size: 24px;
}

.testimonial__user-name.designation {
	font-size: 20px;
}

.testimonial__user-name.comapny-name {
	font-size: 20px;
}

.user__profile-pic {
	max-width: 250px;
}

.mobile__slider-content {
	max-width: 300px;
}

.take__control-content .website__content {
	width: 130%;
}

.take__control-content {
	width: 50%;
	padding-left: 20px;
	padding-right: 20px;
}

.website__footer {
	margin-top: 80px;
}

.footer__section {
	flex-direction: column;
	padding-top: 100px;
	max-width: 700px;
	margin: 0;
}

.footer__download-follow {
	width: 50%;
}

.footer__contact-card.phone-block .footer__contact-card {
	margin-top: -5px;
}

.footer__title {
	font-size: 20px;
}

.footer__download-cards {
	margin-bottom: 40px;
}

.footer__follow-cards a {
	width: 34px;
}

.footer__copy-rights {
	font-size: 14px;
}

.get__intouch-block {
	top: -60px;
}

.get__intouch-wrapper {
	height: 60px;
}

.design-brand {
	font-size: 14px;
}

.scrollToTop {
	right: 20px;
}

}

@media (max-width: 850px) {

.changing-future__card {
	max-width: 45%;
}

}

@media (max-width: 767px) {

.ios-wrapper {
	margin-top: -74px;
}

.m-d-hide {
	display: none !important;
	opacity: 0;
}

.m-d-show {
	display: block !important;
	opacity: 1;
}

.m-hide {
	display: none !important;
	opacity: 0;
}

.m-show {
	display: flex !important;
	opacity: 1;
}

.mob-show {
	display: flex !important;
	opacity: 1;
}

.primary__title {
	font-size: 22px;
}

.primary__title p {
	padding-right: 4px;
}

.website__content {
	font-size: 16px;
	line-height: 1.4;
}

.website__content.white {
	font-size: 16px;
}

.contnet__devider {
	margin-top: 8px;
	height: 1px;
	width: 60px;
}

.get-in__touch-button-wrapper .get-in-touch-button {
	padding: 4.5px 20px;
	min-width: 155px;
	font-size: 16px;
}

.slick-dots li {
	margin: 0px 2px 0px 2px;
}

.slick-dots button {
	width: 8px;
	height: 8px;
}

.slick-dots li.slick-active button {
	width: 23px;
}

div.fixed {
	top: 35px;
	padding-left: 10px;
}

.header-common__block {
	padding: 20px 15px;
}

.header__logo-link {
	max-width: 90px;
}

.header-common__link::after {
	bottom: -4px;
}

.header-common__link a {
	font-size: 20px;
}

.header-common__link + .header-common__link {
	margin-left: 20px;
}

.header-common.header-fixed .header__logo {
	width: 92px;
}

.header-common.header-fixed .header-common__link::after {
	bottom: -4px;
}

.header-common.header-fixed .header-common__link a {
	font-size: 14px;
}

.banner__img-wrapper .banner {
	display: none;
}

.banner__img-wrapper .mobile-banner {
	display: block;
}

.banner__section-content {
	max-width: 460px;
	top: initial;
	bottom: 25%;
}

.banner__section-content .website__content.white {
	font-size: 14px;
}

.banner__section-content .contnet__devider.white {
	margin-bottom: 5px;
}

.banner__main-img {
	top: -28px;
	height: auto;
	width: 80%;
}

.banner__main-img img {
	width: 100%;
	height: auto;
}

.about-banner__section {
	min-height: inherit;
}

.about-banner__section .contnet__devider {
	margin-bottom: 15px;
}

.about-banner__section .banner__section-content {
	max-width: 300px;
	top: 25%;
}

.about-banner__section .website__content {
	font-size: 14px;
}

.making-job__ready {
	padding-top: 0px;
	margin-top: 0px;
	margin-bottom: 100px;
}

.making-job__wrapper {
	position: relative;
	z-index: 99;
	flex-direction: column-reverse;
}

.making-job__bg {
	width: auto;
	margin-top: -190px;
	margin-right: -10px;
}

.making-job__bg img {
	height: 490px;
	width: auto;
	margin-left: auto;
}

.making-job__monkey {
	max-width: 130px;
	right: 280px;
	bottom: -8.6%;
	left: auto;
}

.making-job__did-you-know {
	max-width: 200px;
	z-index: 2;
	right: 8%;
	left: auto;
}

.making-job__content {
	max-width: 100%;
	min-height: 229px;
}

.making-job__main-text {
	margin-top: 10px;
}

.making-job__slider {
	justify-content: flex-end;
	position: relative;
	z-index: 1;
	min-height: 264px;
}

.making-job__each-slider-block.active .making-job__slider-img {
	width: 98px;
	height: 101px;
}

.making-job__slider-img {
	width: 72px;
	height: 73px;
	margin-bottom: 30px;
	margin-top: 30px;
}

.making-job__slider-img.graduation img {
	width: 46px;
}

.making-job__slider-img.progress img {
	width: 48px;
}

.making-job__slider-img.target img {
	width: 42px;
}

.making-job__slider-img.mobile img {
	width: 31px;
}

.changing-future__titles {
	max-width: 350px;
}

.changing-future__section {
	padding-bottom: 50px !important;
	background-image: url("../images/landing/grey-mobile.png");
	background-size: 125% 100%;
	margin-bottom: 20px;
	padding-top: 0;
	margin-top: 50px;
}

.changing-future__card-blocks {
	margin-top: 40px;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
}

.changing-future__card-blocks .changing-future__cards-group:nth-child(1) .changing-future__card:nth-child(1) {
	margin-bottom: 0%;
}

.changing-future__cards-group:nth-child(2n) {
	padding-left: 0px;
}

.changing-future__cards-group {
	flex-direction: column;
}

.changing-future__card {
	padding-bottom: 20px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
}

.changing-future__card:nth-child(2) {
	padding-bottom: 20px;
}

.count__blocks {
	flex-wrap: wrap;
	max-width: 500px;
}

.count__card {
	max-width: 50%;
	text-align: center;
	width: 100%;
	margin-bottom: 10px;
}

.count__card:nth-child(2n) {
	border-right: none;
}

.count__text {
	font-size: 14px;
}

.partners__section {
	margin-top: 50px;
}

.partners__group {
	margin-top: 30px;
}

.partners__logo {
	width: 50%;
	margin-bottom: 10px;
}

.partners__logo-wrapper {
	max-width: 90px;
}

.testimonial__slider-block {
	margin-bottom: 60px;
}

.testimonial__slider {
	min-height: 568px;
	flex-direction: column-reverse;
	padding-right: 0px;
	margin-top: 55px;
	margin-bottom: 30px;
}

.testimonial__slider-conetnt {
	margin-top: 30px;
	margin-bottom: auto;
}

.testimonial__user-name {
	font-size: 20px;
}

.testimonial__user-name.designation {
	font-size: 18px;
}

.testimonial__user-name.comapny-name {
	font-size: 18px;
}

.testimonial__slider-round-pic {
	width: 60px;
	top: -20px;
	left: -20px;
}

.user__profile-pic {
	max-width: 170px;
	border-radius: 20px;
}

.user__profile-pic img {
	border-radius: 20px;
}

.user__profile-pic::after {
	border-radius: 20px;
	left: 10%;
}

.mobile__slides-block {
	flex-direction: column;
	margin-top: 30px;
}

.mobile__slider-title {
	padding: 40px 0px 30px 0px;
}

.mobile__slider-content {
	margin-left: 0px;
	padding-top: 0;
	margin-right: auto;
	margin-top: 20px;
	min-height: 108px;
}

.mobile__slider {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.take__control-img {
	margin-top: 30px;
	order: 2;
}

.take__control-img svg {
	width: 100% !important;
	margin-right: 0;
}

.take__control-bg-img {
	position: absolute;
	width: 100%;
	bottom: 20%;
	margin-top: 0;
}

.take__control-wrapper {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column-reverse;
}

.take__control-content .website__content {
	width: 100%;
	min-height: 200px;
}

.take__control-content {
	position: initial;
	max-width: 400px;
	width: 100%;
	order: 1;
}

.website__footer {
	margin-top: 0;
	background-image: url("../images/footer/footer-mobile-bg.svg");
}

.footer__section {
	padding-top: 50px;
	position: relative;
	padding-bottom: 0;
}

.footer__download-follow {
	margin-top: 0px;
	width: 100%;
}

.footer__contact-cards {
	flex-direction: column;
}

.footer__contact-card img {
	width: 15px;
	margin-right: 6px;
}

.footer__contact-card {
	margin-bottom: 20px;
}

.footer__contact-card .phone-icon {
	margin-top: 3px;
}

.footer__contact-card.phone-block .footer__contact-card {
	margin-top: 15px;
	margin-bottom: 15px;
}

.footer__quick-links .footer__follow-block {
	margin-top: 220px;
}

.footer__quick-links {
	max-width: 280px;
	flex-wrap: wrap;
	margin-top: -80px;
}

.footer__contact-text {
	line-height: 1.4;
}

.footer__quick-link-block {
	margin-left: 0;
	align-items: flex-start;
	flex-wrap: wrap;
}

.footer__quick-link-block a {
	margin-bottom: 5px;
}

.footer__download-cards {
	justify-content: flex-start;
	margin-bottom: 0;
}

.footer__download-cards a {
	padding-right: 10px;
	margin-bottom: 25px;
}

.footer__download-cards a:last-child {
	margin-left: 0;
	padding-right: 0;
}

.footer__download-cards img {
	max-width: 138px;
}

.footer__follow-cards a {
	width: 30px;
}

.footer__copy-rights {
	line-height: 1.4;
	margin-top: 20px;
	flex-wrap: wrap;
}

.footer__copy-rights p {
	margin-left: 0;
}

.footer__download-block {
	margin-bottom: -158px;
	margin-top: 100px;
}

.get__intouch-block {
	position: absolute;
	top: inherit;
	bottom: 96px;
}

.get__intouch-wrapper {
	height: 40px;
	align-items: center;
	padding-right: 5px;
}

.get__intouch-wrapper input[type="email"],
.get__intouch-wrapper input {
	width: calc(100% - 40px);
	font-size: 16px;
	padding: 2px 10px;
}

.get__intouch-wrapper .get-in-touch-button img {
	width: 17px;
	margin: 0px 8px;
}

.get__intouch-wrapper .get-in-touch-button {
	border-radius: 50%;
	min-width: auto;
	width: 32px;
	height: 32px;
	padding: 0;
}

.get__intouch-wrapper .get-in-touch-button p {
	display: none;
}

.design-brand {
	text-align: center;
	margin-top: 10px;
}

.scrollToTop {
	right: 20px;
	bottom: 20px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
}

.scrollToTop img {
	width: 20px;
}

}

@media (max-width: 500px) {

.banner__main-img {
	width: 100%;
}

}

@media (max-width: 424px) {

.mobile__slider-slick {
	border-radius: 16px;
}

}

@media (max-width: 400px) {

.banner__section-content {
	top: 47%;
	bottom: initial;
}

.making-job__bg {
	width: 100%;
	left: 6%;
	top: -16px;
	position: relative;
	margin-right: 0;
}

.making-job__bg img {
	height: auto;
	width: 100%;
}

.making-job__monkey {
	right: auto;
	bottom: -7.5%;
}

}

