.partners__ {
    &section {
        margin-top: 80px;
        @include layout(1199) {
            margin-top: 60px;
        }
        @include layout(1023) {
            margin-top: 40px;
        }
        @include layout(767) {
            margin-top: 50px;
        }
    }
    &wrapper {
        max-width: 1440px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @include layout(1439) {
            max-width: 1280px;
        }
    }
    &group {
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 50px;
        @include layout(1023) {
            margin-top: 40px;
        }
        @include layout(767) {
            margin-top: 30px;
        }
    }
    &logo {
        // width: 33.3%;
        width: 16%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        @include layout(1023) {
            margin-bottom: 40px;
        }

        @include layout(767) {
            width: 50%;
            margin-bottom: 10px;
        }
       
    }
    &logo-wrapper{
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
        max-width: 200px;
        @include layout(1500) {
        }
        @include layout(1439) {
            max-width: 150px;
        }
        @include layout(1279) {
            max-width: 130px;
        }
        @include layout(767) {
            max-width: 90px;
        }
    }
}
