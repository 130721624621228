.count__ {
    &blocks {
        max-width: 1360px;
        width: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include layout(767) {
            flex-wrap: wrap;
            max-width: 500px;
        }
    }
    &card {
        display: inline-flex;
        flex-direction: column;
        border-right: 1px solid;
        padding: 0px 6%;
        margin-bottom: 50px;
        &:last-child {
            border-right: none;
        }
        @include layout(1023) {
            padding: 0px 4%;
            margin-bottom: 40px;
        }
        @include layout(767) {
            max-width: 50%;
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
            &:nth-child(2n) {
                border-right: none;
            }
        }
    }
    &number {
        text-align: center;
    }
    &text {
        font-size: $fs + 8;
        font-weight: 600;
        color: $blue;
        @include layout(1100) {
            font-size: $fs + 2;
        }
        @include layout(767) {
            font-size: $fs - 2;
        }
    }
}
