.testimonial__ {
    &titles {
        display: flex;
        align-items: center;
        justify-content: center;
        .primary__title {
            text-align: center;
        }
    }
    &section {
        margin-top: 150px;
        @include layout(1199){
            margin-top: 100px;
        }
        @include layout(1023){
            margin-top: 80px;
        }
    }
    &slider-block {
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 125px;
        @include layout(1199){
            margin-bottom: 80px;
        }
        @include layout(767){
            margin-bottom: 60px;
        }
    }
    &slider {
        display: flex !important;
        margin-top: 80px;
        margin-bottom: 50px;
        align-items: center;
        justify-content: space-around;
        min-height: 478px;
        @include layout(1680) {
            min-height: 420px;
        }
        @include layout(1279) {
            margin-top: 70px;
            margin-bottom: 70px;
        }
        @include layout(1199) {
            min-height: 356px;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        @include layout(1023) {
            min-height: 310px;
            justify-content: space-between;
            padding-right: 50px;
            margin-bottom: 60px;
            margin-top: 40px;
        }
        @include layout(767) {
            min-height: 568px;
            flex-direction: column-reverse;
            padding-right: 0px;
            margin-top: 55px;
            margin-bottom: 30px;
        }
    }
    &slider-conetnt {
        max-width: 650px;
        width: 100%;
        .quotation {
            width: 94px;
            @include layout(1365) {
                width: 75px;
            }
            @include layout(1023) {
                width: 56px;
            }
        }
        @include layout(1099){
            max-width: 450px;
        }
        @include layout(1023){
            max-width: 380px;
        }
        @include layout(767) {
            margin-top: 30px;
            margin-bottom: auto;
        }
        .website__content {
            padding-top: 10px;
        }
    }
    &user-name {
        font-size: $fs + 12;
        color: $blue;
        font-weight: 600;
        line-height: 1.4;
        @include layout(1023) {
            font-size: $fs + 8;
        }
        @include layout(767) {
            font-size: $fs + 4;
        }
        &.designation{
            color: #4E54C8;
            font-size: $fs + 6;
            @include layout(1023){
                font-size: $fs + 4;
            }
            @include layout(767){
                font-size: $fs + 2;
            }
        }
        &.comapny-name{
            font-size: $fs + 6;
            @include layout(1023){
                font-size: $fs + 4;
            }
            @include layout(767){
                font-size: $fs + 2;
            }
        }
    }
    &slider-profile-pic {
        position: relative;
    }
    &slider-round-pic {
        width: 163px;
        position: absolute;
        left: -64px;
        top: -60px;
        @include layout(1365) {
            width: 125px;
            position: absolute;
            left: -30px;
            top: -30px;
        }
        @include layout(767) {
            width: 60px;
            top: -20px;
            left: -20px;
        }
    }
}
.user__profile-pic {
    max-width: 300px;
    border-radius: 50px;
    position: relative;
    @include layout(1365) {
        max-width: 300px;
    }
    @include layout(1023) {
        max-width: 250px;
    }
    @include layout(767) {
        max-width: 170px;
        border-radius: 20px;
    }
    img {
        position: relative;
        z-index: 1;
        border-radius: 50px;
        @include layout(767) {
            border-radius: 20px;
        }
    }
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: #d8d8d8;
        border-radius: 50px;
        position: absolute;
        left: 11%;
        top: 5%;
        z-index: 0;
        transform: rotate(9deg);
        @include layout(767) {
            border-radius: 20px;
            left: 10%;
        }
    }
}
