.header-common {
    &__block {
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: space-between;
        padding: 30px 40px;
        align-items: center;
        position: relative;
        z-index: 1;
        @include layout(1023) {
            padding: 20px;
        }
        @include layout(767) {
            padding: 20px 15px;
        }
    }
}
.header__links-block {
    display: flex;
}
.header__logo-link {
    max-width: 220px;
    width: 100%;
    display: block;
    transition: all 0.5s ease-in-out;
    @include layout(1366) {
        max-width: 160px;
    }
    @include layout(1023) {
        max-width: 140px;
    }
    @include layout(767) {
        max-width: 90px;
    }
    img{
        transition: all 0.5s ease-in-out;
    }
}
.header-common__link {
    position: relative;
    // padding-left: 70px;
    transition: all 0.5s ease-in-out;
    @include layout(1023) {
        // padding-left: 30px;
    }
    @include layout(767) {
        // padding-left: 15px;
    }
    &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 1px;
        right: 0;
        bottom: -13px;
        background-color: $white;
        transition: all 0.2s ease-in-out;

        @include layout(1023) {
            bottom: -8px;
        }
        @include layout(767) {
            bottom: -4px;
        }
    }
    &.active, &:hover {
        &::after {
            width: 100%;
        }
    }

    a {
        transition: all 0.5s ease-in-out;
        display: block;
        font-size: $fs + 14px;
        color: $white;
        font-weight: 600;
        @include layout(1366) {
            font-size: $fs + 8;
        }
        @include layout(1023) {
            font-size: $fs + 4;
        }
        @include layout(767) {
            font-size: $fs + 4;
        }
    }
}

.header-common__link + .header-common__link {
    margin-left: 50px;
    @include layout(1199) {
        margin-left: 40px;
    }
    @include layout(1023) {
        margin-left: 30px;
    }
    @include layout(767) {
        margin-left: 20px;
    }
}

.header-common {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 901;
    transition: all 0.5s ease-in-out;
    &.header-scroll {
        transition: all 0.5s ease-in-out;
        -webkit-overflow-scrolling: touch;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        top: 0;
        position: relative;
    }
    &.header-fixed {
        position: fixed;
        background:  linear-gradient(223deg, rgba(255, 115, 61,0.5) 0%, rgba(255, 159, 56,0.5) 59%, rgba(254, 141, 62,0.5) 100%);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.5s ease-in-out;
        .header-common__block {
            padding-top: 20px;
            padding-bottom: 20px;
            transition: all 0.5s ease-in-out;
        }
        .header__logo {
            width: 96px;
            // height: 32px;
            transition: all 0.5s ease-in-out;
            @include layout(767) {
                width: 92px;
                // height: 26px;
            }
        }

        .header__logo-block {
            margin-top: -6px;
            align-self: inherit;
            transition: all 0.5s ease-in-out;
        }
        .header-common__link {
            // padding-left: 30px;
            transition: all 0.5s ease-in-out;
            @include layout(767) {
                // padding-left: 10px;
            }
            &::after {
                bottom: -8px;
                transition: all 0.5s ease-in-out;
                @include layout(767) {
                    bottom: -4px;
                }
            }

            a {
                transition: all 0.5s ease-in-out;
                font-size: $fs + 8;
                @include layout(1023) {
                    font-size: $fs;
                }
                @include layout(767) {
                    font-size: $fs - 2;
                }
            }
        }
    }
    &.scrollUp {
	    transform: translate3d(0px,-75px,0px);
        transition: all 0.5s ease-in-out;
    }
}
.loader-block {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}