.mobile__ {
    &slides-block {
        display: flex;
        margin-top: -17%;
        position: relative;
        justify-content: center;
        z-index: 1;
        @include layout(1365) {
            // margin-top: 300px;
        }
        @include layout(1199) {
            // margin-top: 100px;
        }
        @include layout(1023) {
            // margin-top: 70px;
        }
        @include layout(767) {
            flex-direction: column;
            margin-top: 30px;
        }
    }
    &slider-title {
        @include layout(767) {
            padding: 40px 0px 30px 0px;
        }
    }
    &slider-content {
        max-width: 650px;
        width: 100%;
        margin-left: 5%;
        padding-top: 20%;
        @include layout(1919) {
            // padding-top: 12%;
        }
        @include layout(1500) {
            max-width: 500px;
            margin-left: 3%;
        }
        @include layout(1279) {
            max-width: 450px;
        }
        @include layout(1199) {
            max-width: 400px;
        }
        @include layout(1023) {
            max-width: 300px;
        }
        .website__content {
            padding-top: 20px;
        }
        @include layout(767) {
            margin-left: 0px;
            padding-top: 0;
            margin-right: auto;
            margin-top: 20px;
            min-height: 108px;
        }
        .primary__title {
            span {
                display: block;
            }
        }
    }
    &slider {
        max-width: 641px;
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-left: -8%;
        &::before {
            content: "";
            width: 25%;
            height: 4%;
            background: black;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            position: absolute;
            top: 2%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 3;
        }
        img {
            position: relative;
            z-index: 1;
        }
        @include layout(1679) {
            max-width: 500px;
        }
        @include layout(1199) {
            max-width: 350px;
            margin-left: -5%;
        }
        @include layout(767) {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &slider-slick {
        position: absolute;
        top: 3.3%;
        left: 23.4%;
        height: 91.5%;
        width: 52.8%;
        overflow: hidden;
        z-index: 2;
        border-radius: 20px;
        @include layout(424) {
            border-radius: 16px;
        }
    }
    &bg-image-block {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-bottom: -68%;
    }
}

.take__ {
    &control-img {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include layout(767) {
            margin-top: 30px;
            order: 2;
        }
        svg {
            width: 60% !important;
            margin-right: 10%;
            @include layout(767) {
                width: 100% !important;
                margin-right: 0;
            }
        }
    }
    &control-bg-img {
        margin-top: -10%;
        @include layout(767) {
            position: absolute;
            width: 100%;
            bottom: 20%;
            margin-top: 0;
        }
    }
    &control-section {
        position: relative;
        margin-bottom: 30px;
        @include layout(1100) {
            // margin-bottom: 100px;
        }
        @include layout(1023) {
            // margin-bottom: 80px;
        }
        @include layout(767) {
            // margin-bottom: 60px;
        }
    }
    &control-wrapper {
        position: relative;
        // top: 50px;
        @include layout(1365) {
            // top: 20px;
        }
        @include layout(767) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }
    }
    &control-img {
        svg {
            width: 100%;
        }
    }
    &control-content {
        position: absolute;
        top: 10%;
        width: 65%;
        padding-left: 40px;
        .website__content {
            width: 80%;
            padding-top: 20px;
            @include layout(1023) {
                width: 130%;
            }
            @include layout(767) {
                width: 100%;
                min-height: 200px;
            }
        }

        .primary__title span {
            display: inherit;
        }

        @include layout(1919) {
            width: 60%;
        }
        @include layout(1199) {
            width: 50%;
            padding-left: 30px;
        }
        @include layout(1023) {
            width: 50%;
            padding-left: 20px;
            padding-right: 20px;
        }
        @include layout(767) {
            position: initial;
            max-width: 400px;
            width: 100%;
            order: 1;
        }
    }
}