.banner__ {
    &img-wrapper {
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(200deg,#ff943c, rgba(255, 148, 60, 0)30%);
            right: 0;
            top: 0;
            margin: auto;
        }
        .banner {
            @include layout(767) {
                display: none;
            }
        }
        .mobile-banner {
            display: none;
            @include layout(767) {
                display: block;
            }
        }
    }
    &section {
        min-height: 535px;
        position: relative;
        .get-in__touch-button-wrapper {
            margin-top: 34px;
            @include layout(1023) {
                margin-top: 20px;
            }
        }
        &-content {
            position: absolute;
            z-index: 3;
            left: 0;
            top: 30%;
            max-width: 1024px;
            width: 100%;
            max-width: 900px;
            @include layout(1760) {
                top: 25%;
                max-width: 770px;
            }
            @include layout(1600) {
            }
            @include layout(1279) {
                max-width: 630px;
            }
            @include layout(1199) {
                top: 30%;
                max-width: 570px;
            }
            @include layout(1023) {
                max-width: 400px;
                top: 20%;
            }
            @include layout(767) {
                max-width: 460px;
                top:initial;
                bottom: 25%;
            }
            @include layout(400){
                top: 47%;
                bottom: initial;
            }
            .website__content.white {
                line-height: 1.4;
                font-weight: 400;
                font-size: 28px;
                @include layout(1199){
                    font-size: $fs + 4;
                }
                @include layout(1023) {
                    font-size: $fs + 2;
                }
                @include layout(767){
                    font-size: $fs - 2;
                }
                span {
                    display: inline-block;
                }
            }
            .contnet__devider.white {
                margin-bottom: 15px;
                @include layout(1023) {
                    margin-bottom: 10px;
                }
                @include layout(767){
                    margin-bottom: 5px;
                }
            }
        }
    }
    &main-img {
        position: absolute;
        right: -10px;
        top: 0;
        height: 100%;
        z-index: 1;
        @include layout(767) {
            top: -28px;
            height: auto;
            width: 80%;
        }
        @include layout(500){
            width: 100%;
        }
        img {
            height: 100%;
            width: auto;
            @include layout(767){
                width: 100%;
                height: auto;
            }
        }
    }
}
.about-banner__section {
    min-height: 430px;
    
    @include layout(767) {
        min-height: inherit;
    }
    .contnet__devider {
        margin-top: 5px;
        margin-bottom: 25px;
        @include layout(767){
            margin-bottom: 15px;
        }
    }
    .banner__section-content {
        max-width: 940px;
        @include layout(1919) {
            max-width: 850px;
        }
        @include layout(1679) {
            max-width: 750px;
        }
        @include layout(1439) {
            max-width: 670px;
        }
        @include layout(1365) {
            max-width: 630px;
        }
        @include layout(1199) {
            max-width: 540px;
        }
        @include layout(1023) {
            max-width: 400px;
        }
        @include layout(767) {
            max-width: 300px;
            top: 25%;
        }
    }
    .website__content{
        font-size: 30px;
        @include layout(1919){
            font-size: $fs + 8;
        }
        @include layout(1199){
            font-size: $fs + 4;
        }
        @include layout(1023) {
            font-size: $fs + 2;
        }
        @include layout(767){
            font-size: $fs - 2;
        }
    }
}
.header-banner__section {
    &.home-banner {
        margin-top: -5px;
        max-width: 1920px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
    }
}
