// fonts start
@font-face {
    font-family: 'SF-Pro-Bold';
    src: url('../fonts/SF-Pro-Display-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

// fonts end

// the above all Lato fonts are having total 5 ramaining 3 (thin-100, extra-light-200, medium-500, black-900) are only dummy.
// Value           Common weight name 

// 100             Thin (Hairline)
// 200             Extra Light (Ultra Light)
// 300             Light
// 400             Normal
// 500             Medium
// 600             Semi Bold (Demi Bold)
// 700             Bold
// 800             Extra Bold (Ultra Bold)
// 900             Black (Heavy)


// $fs: 20px;

$sfb: "SF-Pro-Bold"; // light-300, normal-400, medium-500, semibold-600 bold-700